.App {
  display: grid;
  width: 100%;
  grid-template-areas:
    "header"
    "messages "
    "wrapper";
  grid-template-rows: 5em 1fr;
  font-size: 1rem;
}

header {
  grid-area: header;
  background-color: #1E427C;
  color: white;
  padding: 0.2rem 6rem;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  justify-content: space-between;
  /* TODO: Add these back to make the header fixed. It just breaks the showing of messages currently */
  /*position: fixed;
  width: 83vw;*/
  box-shadow: 0 2px 4px -2px black;
}
@media screen and (max-width: 1024px) {
  header {
    padding: 1.5rem 1rem;
  }
}

main {
  grid-area: main;
}

nav {
  grid-area: nav;
  /* TODO: Add these back to make the side nav fixed. */
  /*position: fixed;*/
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
