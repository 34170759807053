/* Custom FE styling for Flatpickr */
.flatpickr-calendar {
    padding: 4px !important;
    width: auto !important;
}
.flatpickr-innerContainer {
    margin-top: 8px !important;
}
.flatpickr-current-month {
    padding: 0 !important;
}
.flatpickr-weekdays {
    height: 36px !important;
    border-bottom: 2px solid #1A6EAD !important;
    background-color: #F5F5F5 !important;
}
.flatpickr-days {
    margin-top: 4px;
}
.flatpickr-day {
    border: 2px solid transparent !important;
    font-weight: 700 !important;
    border-radius: 6px !important;
    font-size: 16px !important;
    margin: 1px !important;
}
.flatpickr-day.today {
    border-color: #0A0A0A !important;
}
.flatpickr-day.selected {
    border-color: #1A6EAD !important;
    background-color: #1A6EAD !important;
}
.flatpickr-day.flatpickr-disabled {
    color: #464646 !important;
    font-weight: 400 !important;
    background-color: #D2D2D2 !important;
}
.flatpickr-disabled.flatpickr-past-date {
    background-color: transparent !important;
}
.flatpickr-next-month, .flatpickr-prev-month {
    background: #1A6EAD !important;
    color: white !important;
    border-radius: 4px !important;
    margin: 4px 4px 0 4px !important;
    padding: 4px 6px 0 8px !important;
    position: relative !important;
    height: auto !important;

    svg > path {
        fill: currentColor !important;
    }
}
.flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay {
    color: #6E6E6E !important;
}
.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
    cursor: default !important;
}
.flatpickr-day:focus:not(.flatpickr-day.flatpickr-disabled), .flatpickr-day:hover:not(.flatpickr-day.flatpickr-disabled) {
    background: #d1e6f5 !important;
    color: inherit !important;
}